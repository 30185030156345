import { render, staticRenderFns } from "./viejo.vue?vue&type=template&id=288f7a03&scoped=true&"
import script from "./viejo.vue?vue&type=script&lang=js&"
export * from "./viejo.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "288f7a03",
  null
  
)

export default component.exports

/* vue-automatic-import-loader */
import autoInstallComponents from "!/workspace/node_modules/vue-automatic-import-loader/lib/runtime/autoInstallComponents.js"
import WAudioList from '@/components/WAudioList.vue'
import WLoading from '@/components/WLoading.vue'
import WRecordAudio from '@/components/WRecordAudio.vue'
import WSwitch from '@/components/WSwitch.vue'
import WTopBar from '@/components/WTopBar.vue'
autoInstallComponents(component, {WAudioList,WLoading,WRecordAudio,WSwitch,WTopBar})
