<template>
  <div>
    <w-top-bar>Audio - Complemento de compra</w-top-bar>
    <w-record-audio
      :key="showRecordAudio._id"
      :value="showRecordAudio"
      v-if="showRecordAudio"
      @hide="showRecordAudio = false"
      @nextOrClose="nextOrClose"
    />
    <div class="h-full p-5 bg-gray-200">
      <div
        v-if="!isFindRegaloAudioPending"
        class="flex items-center justify-end h-12 px-1"
      >
        <div class="flex items-center text-xs">
          <w-switch
            :value="mostrarProcesados"
            @input="mostrarProcesados = !mostrarProcesados"
          ></w-switch>
          <span>Mostrar Procesados</span>
        </div>
      </div>
      <transition
        leave-active-class="transition-all duration-300 ease-in-out"
        enter-active-class="transition-all duration-200 ease-in-out"
        enter-class="opacity-0 "
        enter-to-class="opacity-100 "
        leave-to-class="opacity-100 "
        leave-class="opacity-0 "
      >
        <w-audio-list
          v-if="!isFindRegaloAudioPending"
          @remove="toRemove($event)"
          @play="showRecordAudio = $event"
          @record="showRecordAudio = $event"
          :items="regaloAudio"
          :key="listKey"
        />
        <w-loading v-else />
      </transition>
    </div>
  </div>
</template>

<script>
// import { mapActions } from "vuex";
import WLoading from "../../components/WLoading.vue";
import { mapActions } from "vuex";
import { makeFindMixin } from "feathers-vuex/dist";

export default {
  components: { WLoading },
  name: "regaloAudioList",
  data() {
    return {
      listKey: 1,
      mostrarProcesados: false,
      showRecordAudio: false,
      listado: [],
    };
  },
  created() {
    // List "regalo-coupon" from database
    this.load();
  },
  computed: {
    regaloAudioParams() {
      let query = {
        deleted: { $ne: true },
      };
      if (!this.mostrarProcesados)
        query.estado = {
          $ne: "procesado",
        };

      return {
        query: {
          // ...query,
          $sort: {
            createdAt: -1,
          },
        },
      };
    },
    dedicatoriaParams() {
      return {
        query: {},
      };
    },
  },
  watch: {
    mostrarProcesados() {
      this.load();
    },
  },
  mixins: [makeFindMixin({ service: "regalo-audio" })], // Step 2
  methods: {
    ...mapActions("shipments", ["find"]),
    async load() {
      const query = {};
      if (!this.mostrarProcesados) {
        query.estado = {
          $ne: "procesado",
        };
      }

      const resultado = await find({
        query,
      });

      console.log("listado", resultado);
      console.log("listado", resultado.data);
    },
    async updateView() {
      this.forceShow = true;
      await this.findRegaloAudio();
      this.forceShow = false;
      this.$nextTick(() => {
        this.forceShow = false;
      });
    },
    async nextOrClose() {
      console.log("Next or close", this.showRecordAudio);
      this.regaloAudio[this.showRecordAudio.index].procesado = true;
      // Reload list
      this.listKey++;

      // this.updateView();
      if (this.showRecordAudio && this.showRecordAudio.index !== null) {
        console.log("Index", this.showRecordAudio.index);
        let nextIndex = this.showRecordAudio.index++;
        let existe = this.regaloAudio[nextIndex];
        while (existe && existe.estado === "procesado") {
          nextIndex++;
          existe = this.regaloAudio[nextIndex];
        }
        // Buscamos incrementando el index, mientras estado === 'procesado'

        console.log("Existe el siguiente", existe);
        if (existe)
          this.showRecordAudio = {
            audio: existe,
            index: this.showRecordAudio.index + 1,
          };
        else this.showRecordAudio = false;
      } else {
        this.showRecordAudio = false;
      }
    },
    async toRemove(id) {
      console.log(id);
      await this.remove(id);
    },
  },
};
</script>

<style lang="scss" scoped></style>
