<template>
  <div class="flex flex-col">
    <div class="-my-2 overflow-x-auto md:overflow-hidden sm:-mx-6 lg:-mx-8">
      <div
        class="inline-block min-w-full py-2 overflow-hidden align-middle sm:px-6 lg:px-8"
      >
        <div
          class="flex flex-col items-center justify-center h-48"
          v-if="audios.length === 0"
        >
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              class="w-12 h-12 text-base-300"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </div>
          <div class="my-2 font-normal text-gray-700">No hay audios</div>
        </div>
        <div
          v-else
          class="overflow-hidden shadow baudio-b baudio-gray-200 sm:rounded-lg"
        >
          <table
            class="min-w-full overflow-hidden divide-y divide-gray-200 rounded-lg"
          >
            <thead
              class="hidden md:table-header-group print:table-header-group"
            >
              <tr>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase print:hidden md:table-cell bg-gray-50"
                >
                  Nombre
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase print:hidden bg-gray-50"
                >
                  Detalles
                </th>
                <th
                  class="hidden px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase print:hidden md:table-cell bg-gray-50"
                >
                  Estado
                </th>
                <th
                  class="py-3 text-xs font-medium leading-4 tracking-wider text-right text-gray-500 uppercase print:hidden sm:hidden bg-gray-50"
                >
                  Procesado
                </th>
              </tr>
            </thead>
            <tbody
              class="flex-1 bg-white divide-y divide-gray-300 sm:flex-none print:flex-none"
            >
              <tr
                class="flex flex-col mb-2 flex-no wrap sm:table-row print:table-row sm:mb-0"
                v-for="(audio, indexAudio) in audios"
                :key="audio._id"
              >
                <td class="px-6 py-3 overflow-hidden whitespace-no-wrap">
                  <div class="text-sm">
                    {{ audio.comprador }}
                  </div>
                  <div class="text-sm font-semibold">{{ audio.email }}</div>
                  <div class="text-sm">{{ audio.telefono }}</div>
                </td>
                <td class="px-6 text-sm whitespace-no-wrap md:py-3">
                  <!-- Contar las palabra en audio.texto -->
                  <div v-if="audio.texto" class="text-sm">
                    {{
                      audio.texto.split(" ").filter((word) => word.length > 0)
                        .length
                    }}
                    palabras
                  </div>
                  <div class="text-sm">
                    Se envia <strong>{{ audio.cuando }}</strong>
                    <!-- {{ audio.fechaEnvio | moment("hh:mm[hs] DD/MM/YY") }} -->
                  </div>
                </td>
                <td
                  class="px-6 py-4 text-sm whitespace-no-wrap md:table-cell"
                  style="vertical-align: top"
                >
                  <!-- Icono de estado pendiente/procesado/enviado -->
                  <span
                    v-if="!audio.estado"
                    class="inline-flex px-2 text-xs font-medium leading-5 text-orange-100 bg-orange-400 rounded-full"
                  >
                    Pendiente
                  </span>
                  <span
                    v-else-if="audio.estado === 'procesado'"
                    class="inline-flex px-2 text-xs font-medium leading-5 text-green-100 bg-green-500 rounded-full"
                  >
                    Procesado
                  </span>
                  <span
                    v-else-if="audio.estado === 'enviado'"
                    class="inline-flex px-2 text-xs font-medium leading-5 text-blue-100 bg-blue-500 rounded-full"
                  >
                    Enviado
                  </span>
                  <div v-if="audio.sentAt" class="text-xs italic text-gray-500">
                    hace
                    {{ audio.sentAt }}
                  </div>
                </td>
                <!-- <td class="hidden px-6 py-4 whitespace-no-wrap md:table-cell">
                  <span
                    v-if="audio.archive"
                    class="inline-flex px-2 text-xs font-medium leading-5 text-green-100 bg-green-500 rounded-full"
                  >
                    Archivada
                  </span>
                  <span
                    v-else
                    class="inline-flex px-2 text-xs font-medium leading-5 text-orange-100 bg-orange-400 rounded-full"
                  >
                    Pendiente
                  </span>
                  <div class="text-xs italic text-gray-500">
                    hace
                    {{
                      audio.foodUpdated ||
                      audio.createdAt | moment("from", true)
                    }}
                  </div>
                </td> -->
                <td
                  style="vertical-align: top"
                  class="px-6 py-4 text-sm font-medium leading-5 text-right whitespace-no-wrap md:w-32"
                >
                  <div class="relative">
                    <!-- Icono para grabar -->
                    <button
                      v-if="!audio.estado"
                      @click="abrirModal(indexAudio)"
                      class="inline-flex items-center px-2 py-2 text-sm font-medium leading-3 text-orange-100 transition-colors duration-150 bg-orange-500 border border-transparent rounded-lg active:bg-orange-600 hover:bg-orange-600 focus:outline-none focus:shadow-outline-orange"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-4 h-4"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M12 18.75a6 6 0 006-6v-1.5m-6 7.5a6 6 0 01-6-6v-1.5m6 7.5v3.75m-3.75 0h7.5M12 15.75a3 3 0 01-3-3V4.5a3 3 0 116 0v8.25a3 3 0 01-3 3z"
                        />
                      </svg>
                      Grabar
                    </button>
                    <!-- Escuchar con audio player oculto -->
                    <button
                      v-else
                      @click="$emit('play', audio)"
                      class="inline-flex items-center px-2 py-2 text-sm font-medium leading-3 text-blue-100 transition-colors duration-150 bg-blue-500 border border-transparent rounded-lg active:bg-blue-600 hover:bg-blue-600 focus:outline-none focus:shadow-outline-blue"
                    >
                      <!-- Boton play heroicons -->
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-4 h-4 mr-1"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M15.91 11.672a.375.375 0 010 .656l-5.603 3.113a.375.375 0 01-.557-.328V8.887c0-.286.307-.466.557-.327l5.603 3.112z"
                        />
                      </svg>

                      Escuchar
                    </button>
                  </div>
                </td>
              </tr>

              <!-- More rows... -->
            </tbody>
          </table>
          <div
            v-if="totalResults > limit"
            @click="nextPage()"
            class="items-center p-3 text-sm font-medium text-center text-indigo-300 cursor-pointer glex hover:text-indigo-500"
          >
            Cargar 20 más de {{ totalResults - limit }} resultados.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    hide: {
      type: Array,
      default: () => [],
    },
    totalResults: {
      type: Number,
      default: 0,
    },
    eventPrice: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      showImage: null,
      upload: null,
      limit: 20,
      busy: false,
    };
  },
  methods: {
    abrirModal(indexAudio) {
      const audio = this.items[indexAudio];
      // Siguiente audio
      this.$emit("record", { audio: audio, index: indexAudio });
    },
    remove(id) {
      this.$emit("remove", id);
    },
    nextPage() {
      this.limit += 20;
      this.$emit("next-page", this.limit);
    },
  },
  computed: {
    audios() {
      return this.items.filter((audio) => audio.dedicatoria);
    },
  },
};
</script>

<style lang="css" scoped>
@media (min-width: 640px) {
  table {
    display: inline-table !important;
  }

  thead tr:not(:first-child) {
    display: none;
  }
}

td:not(:last-child) {
  baudio-bottom: 0;
}

th:not(:last-child) {
  baudio-bottom: 2px solid rgba(0, 0, 0, 0.1);
}
</style>
